 

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { MasterTemplate } from '@appbase/templates/'

@Component({
  components: { MasterTemplate },
})
export default class SurveyMaster extends Vue {
    
}
